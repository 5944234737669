<script setup lang="ts">
import type { IBasicVideo } from "~/types";

const props = defineProps<{
  video: IBasicVideo;
  categoryName?: string;
}>();

const { t } = useI18n();
const baseURL = useRuntimeConfig().app.baseURL;
const fallbackImageSrc = `${baseURL}images/no_poster.jpg`;
</script>

<template>
  <div
    class="flex max-md:max-w-xs md:w-[344px] h-[72px] bg-[rgba(0,_0,_0,_0.72)] absolute left-4 bottom-[66px] rounded overflow-hidden cursor-pointer"
  >
    <div class="flex w-2/5">
      <RImage
        class="relative object-fill"
        :src="props.video.urls.poster"
        :fallback-src="fallbackImageSrc"
      />
    </div>
    <div class="flex flex-col w-3/5 px-2 py-2 text-truncate">
      <div class="text-sm font-medium text-neutral-light-500">
        {{ t("labels.next") }}
      </div>
      <div class="text-sm text-white font-medium line-clamp-2">
        {{ props.categoryName ? props.categoryName + " • " + props.video.title : props.video.title }}
      </div>
    </div>
  </div>
</template>

import type { Ref, ShallowRef } from "vue";
import type { VideoJsPlayer as IPixellotPlayer } from "@pixellot/web-sdk";
import type { IPlayerState } from "../util/state";
import type { IPixellotPlayerSource, IPixellotPlayerSourceType } from "../types";
import { getThumbnailFromUrl, updateThumbnailsInPlayer } from "../util/source/thumb";
import { ref, shallowReactive } from "vue";
import { setupAdvertisementPlugin } from "@pixellot/web-sdk";

export default function useSource(
  videoJSPlayer: ShallowRef<IPixellotPlayer | null>,
  state: Ref<IPlayerState | null>,
  options?: {
    getStartTimeOnSourceSwitch?:
      | null
      | ((params: {
          oldSrc: IPixellotPlayerSource;
          newSrc: IPixellotPlayerSource;
          playedTime: number;
        }) => number | Promise<number>);
  },
) {
  const sources = shallowReactive<Map<IPixellotPlayerSourceType, IPixellotPlayerSource>>(new Map());
  const activeSource = ref<IPixellotPlayerSource>({
    type: "hd",
    url: "",
    thumbnails_url: null,
    ads: null, // Not supported yet on the current(Play) platform
  });

  function switchSourceToType(type: IPixellotPlayerSourceType) {
    const player = videoJSPlayer.value!;
    const playedTime = player.currentTime();
    const wasPlaying = !player.paused();
    const playedVolume = player.volume();
    const oldSrc = JSON.parse(JSON.stringify(activeSource.value));
    const playIfPlayed = () => {
      // Trigger play if video was playing in the moment of switching to this new source type
      if (wasPlaying && player.paused()) {
        const promise = player.play();

        if (promise !== undefined) {
          promise.catch((error) => {
            // 90% AbortError, means that play() was aborted by the browser.
            // Easy reproducible on iOS devices.
            console.warn(error);
          });
        }
      }
    };

    const newSrc = sources.get(type);

    if (!newSrc) {
      return player.error(`Failed to switch to source with type '${type}'!`);
    }

    setSrc(newSrc);

    player.volume(playedVolume);

    const isLive = state.value?.isLive;

    player.one("loadedmetadata", async () => {
      // TODO: Investigate the switching into/between live & vod streams
      if (isLive) {
        return; // Do nothing for now
      }

      if (options?.getStartTimeOnSourceSwitch) {
        const params = { oldSrc, newSrc, playedTime };
        const time = await options.getStartTimeOnSourceSwitch(params);
        player.currentTime(time);
      } else if (player.currentTime() !== playedTime) {
        player.currentTime(playedTime); // Switch to cached time
      }

      playIfPlayed();

      player.panoAPI.reset();
    });
  }

  function changeSources(newSrc?: null | string | IPixellotPlayerSource | IPixellotPlayerSource[]) {
    // clear any existing sources data
    sources.clear();

    if (!newSrc) {
      console.warn("[PixellotWebSDK:Player] Received empty source.. Please provide a valid source!");
      return;
    }

    if (typeof newSrc === "string") {
      sources.set("hd", { type: "hd", url: newSrc });
    } else if (Array.isArray(newSrc)) {
      newSrc.forEach((src) => sources.set(src.type, src));
    } else {
      sources.set(newSrc.type, newSrc);
    }

    playAvailableSource();
  }

  function playAvailableSource() {
    const player = videoJSPlayer.value!;
    const sourcePlayOrder: IPixellotPlayerSourceType[] = ["hd", "pano", "tactical"];

    const availableSourceType = sourcePlayOrder.find((type) => sources.has(type));
    const nextSrc = availableSourceType ? sources.get(availableSourceType) : null;

    if (nextSrc) {
      setSrc(nextSrc);
    } else {
      player.error("No available sources found!");
    }
  }

  function setSrc(src: IPixellotPlayerSource) {
    const player = videoJSPlayer.value!;
    const hasSrc = Boolean(player.src());

    // Unload any existing source from the player before applying new
    if (hasSrc && player.unload) {
      player.unload({ loading: true });
    }

    // Update current active source
    activeSource.value = src;
    player.src(src.url);

    // Handle thumbnails
    if (src.thumbnails_url) {
      const thumb = getThumbnailFromUrl(src.thumbnails_url);

      updateThumbnailsInPlayer(player, thumb);
    }

    // Handle clipping
    player.clip(src.clip);

    // Handle Pano
    player.one("loadedmetadata", () => player.panoAPI.reset());

    if (src.type === "pano") {
      player.one("loadedmetadata", () => player.panoAPI.switchMode("full"));
    }

    // Handle Ads
    if (src.ads && src.ads.length > 0) {
      // Setup Ads plugin only if AdsAPI is not available yet.
      if (!player.adsAPI) {
        setupAdvertisementPlugin(player);
      }

      if (player.adsAPI) {
        player.adsAPI.scheduleAds(src.ads);
      } else {
        console.error("[PixellotWebSDK:Player] `adsAPI` is not available for ads scheduling!");
      }
    }
  }

  return { sources, switchSourceToType, changeSources, activeSource, setSrc };
}
